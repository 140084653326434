exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-export-hay-js": () => import("./../../../src/pages/export-hay.js" /* webpackChunkName: "component---src-pages-export-hay-js" */),
  "component---src-pages-grain-hay-price-series-js": () => import("./../../../src/pages/grain-hay-price-series.js" /* webpackChunkName: "component---src-pages-grain-hay-price-series-js" */),
  "component---src-pages-hay-fodder-forecasting-js": () => import("./../../../src/pages/hay-fodder-forecasting.js" /* webpackChunkName: "component---src-pages-hay-fodder-forecasting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-hay-js": () => import("./../../../src/pages/international-hay.js" /* webpackChunkName: "component---src-pages-international-hay-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

